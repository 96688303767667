import React, { useState } from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from '../base.styles';
import { filterDrawerStyles } from './base.styles';
import { ScrollView, TouchableWithoutFeedback, View, Text } from 'react-native';
import ListHeader from '@gf/cross-platform-lib/providers/DrawerProvider/DrawerComponents/ListHeader/ListHeader';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks/useMediaQuery';
import SelectableItem from '@gf/cross-platform-lib/components/SelectableItem/SelectableItem';
import { GFImage } from '@gf/cross-platform-lib/components/Image/Image';
import { FILTER_BATCH, activityIcons } from '@gf/cross-platform-lib/constants';
import {
  ActivityFilter,
  FilterCategory,
  SelectedFilter,
  getSelectableItemData
} from '@gf/cross-platform-lib/utils/filterUtils';
import { correctHexColor, invertColor } from '@gf/cross-platform-lib/utils';

export interface FilterDrawerProps {
  drawerId: string;
  dialogSubtitle: string;
  filters: ActivityFilter[] | string[];
  filterCategory: FilterCategory;
  primaryColor?: string;
  selectedFilter: SelectedFilter;
  applyFilter: (type: string, selected: boolean, params: any) => void;
  shouldShowPlayOff?: boolean;
}

const _calculateHeight = (isMobile: boolean, isNativeApp: boolean) => {
  if (isMobile) {
    return '100%';
  } else {
    return isNativeApp ? '100vh' : 'calc(100%)';
  }
};

export const FilterDrawer = ({
  drawerId,
  dialogSubtitle,
  filters,
  filterCategory,
  primaryColor = '000',
  selectedFilter,
  applyFilter,
  shouldShowPlayOff = false
}: FilterDrawerProps) => {
  const { isMobile, isNativeApp } = useMediaQuery();
  const [drawerFilterState, setDrawerFilterState] = useState<SelectedFilter>({ ...selectedFilter });
  const invertedPrimaryColorBW = invertColor(primaryColor, true);
  const containerHeight = _calculateHeight(isMobile, isNativeApp);

  return (
    <TouchableWithoutFeedback accessible={false}>
      <Container containerHeight={containerHeight}>
        <ListHeader
          drawerId={drawerId}
          variation='variation-done-button'
          closeFunction={() => applyFilter(FILTER_BATCH, true, drawerFilterState)}
        />
        <SubtitleContainer>
          <SubtitleText>{dialogSubtitle}</SubtitleText>
        </SubtitleContainer>
        <ScrollView showsHorizontalScrollIndicator={false} bounces={false} showsVerticalScrollIndicator={false}>
          {shouldShowPlayOff && (
            <>
              <SelectableItem
                key='playoffs'
                value='true'
                isSelected={drawerFilterState.playoffs}
                onPress={() => {
                  drawerFilterState.playoffs
                    ? setDrawerFilterState({
                        ...drawerFilterState,
                        playoffs: false
                      })
                    : setDrawerFilterState({ ...drawerFilterState, playoffs: true });
                }}
              >
                <FilterItemContainer>
                  <IconCircle primaryColor={primaryColor}>
                    <IconContainer>
                      <GFImage name={activityIcons.playoffs} width={12.25} height={10.55} tintColor={'#F6F6FA'} />
                    </IconContainer>
                  </IconCircle>
                  <FilterItemText numberOfLines={1}>Playoffs</FilterItemText>
                </FilterItemContainer>
              </SelectableItem>
              <PlayoffsDivider />
            </>
          )}
          {filters.map((filter: any) => {
            const { isSelected, filterIcon, filterName } = getSelectableItemData(
              filterCategory,
              filter,
              drawerFilterState
            );

            return (
              <SelectableItem
                key={filterName}
                value={filterName}
                isSelected={isSelected}
                onPress={() => {
                  isSelected
                    ? setDrawerFilterState({
                        ...drawerFilterState,
                        [`${filterCategory}`]: drawerFilterState[`${filterCategory}`].filter(
                          (activity: any) => activity !== filterName
                        )
                      })
                    : setDrawerFilterState({
                        ...drawerFilterState,
                        [`${filterCategory}`]: [...drawerFilterState[`${filterCategory}`], filterName]
                      });
                }}
              >
                <FilterItemContainer>
                  <IconCircle primaryColor={correctHexColor(primaryColor)}>
                    <IconContainer>
                      <GFImage name={filterIcon} width={12.25} height={10.55} tintColor={invertedPrimaryColorBW} />
                    </IconContainer>
                  </IconCircle>
                  <FilterItemText numberOfLines={1}>{filterName}</FilterItemText>
                </FilterItemContainer>
              </SelectableItem>
            );
          })}
        </ScrollView>
      </Container>
    </TouchableWithoutFeedback>
  );
};
const Container = styled(View)`
  ${filterDrawerStyles.container}
`;
const SubtitleContainer = styled(View)`
  ${filterDrawerStyles.subtitleContainer}
`;
const SubtitleText = styled(Text)`
  ${filterDrawerStyles.subtitleText}
`;
const IconCircle = styled(View)<{ primaryColor: string }>`
  ${styles.iconCircle};
`;
const IconContainer = styled(View)`
  ${styles.iconContainer};
`;
const FilterItemContainer = styled(View)`
  ${styles.filterItemContainer};
`;
const FilterItemText = styled(Text)`
  ${styles.filterItemText};
`;
const PlayoffsDivider = styled(View)`
  ${styles.playoffsDivider};
`;

export default FilterDrawer;
