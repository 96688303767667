import React from 'react';
import { TouchableOpacity, GestureResponderEvent, View } from 'react-native';
import { CheckboxEmpty, CheckboxFilled } from '@gf/cross-platform-lib/components/Icons/Carbonicons/collections';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './base.styles';
import { testProperties } from '@gf/cross-platform-lib/utils';

export const SelectableItem: React.FC<SelectableItemProps> = ({ children, onPress, isSelected }) => {
  return (
    <TouchableOpacity
      accessible={false}
      onPress={onPress}
      style={[styles.item, isSelected && styles.selectedItem]}
      {...testProperties('selectable-item')}
    >
      <ItemContainer>{children}</ItemContainer>
      {isSelected ? <CheckboxFilled style={styles.iconStyle} /> : <CheckboxEmpty style={styles.iconStyle} />}
    </TouchableOpacity>
  );
};

export default SelectableItem;

const ItemContainer = styled(View)`
  ${styles.itemContainer}
`;

interface SelectableItemProps {
  value: string;
  onPress: (event: GestureResponderEvent) => void;
  isSelected: boolean;
  children: React.ReactNode;
}
