import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './base.styles';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { TouchableWithoutFeedback } from 'react-native';
import { SCHOOL_DISTRICT_LIST } from '@gf/cross-platform-lib/providers';
import ListHeader from '@gf/cross-platform-lib/providers/DrawerProvider/DrawerComponents/ListHeader/ListHeader';

const Container = styled.View`
  ${styles.container}
`;
const Title = styled.Text`
  ${styles.title}
`;

interface EmptySchoolDistrictProps {
  schoolName: string;
}

export const EmptySchoolDistrict = ({ schoolName }: EmptySchoolDistrictProps) => {
  const { isNativeApp, isMobile } = useMediaQuery();

  return (
    <TouchableWithoutFeedback>
      <Container isNativeApp={isNativeApp} isMobile={isMobile}>
        <ListHeader headerTitle={`${schoolName} District Schools`} drawerId={SCHOOL_DISTRICT_LIST} />
        <Title>
          We couldn&apos;t find the schools you&apos;re looking for. Please try again later or choose a different
          district.
        </Title>
      </Container>
    </TouchableWithoutFeedback>
  );
};

export default EmptySchoolDistrict;
