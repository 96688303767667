import { Event } from '@gf/cross-platform-lib/interfaces';
import isEmpty from 'lodash/isEmpty';

export const isStreamingOrSiteEvent = (event: Event, siteDomain?: string) => {
  if (
    isEmpty(event?.ticketTypes) &&
    (event?.eventIntegrationDetails?.some(item => item.isBroadcast) ||
      (event?.eventIntegrationDetails?.every(item => !item.isBroadcast) && !isEmpty(siteDomain)))
  ) {
    return true;
  }
  return false;
};
