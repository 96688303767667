import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  container: css<{ isNativeApp: boolean; isMobile: boolean }>`
    display: flex;
    transition: all 0.35s;
    padding: 32px;
    height: ${({ isNativeApp, isMobile }) => {
      if (isMobile) {
        return '100%';
      }
      return isNativeApp ? '100vh' : 'calc(100% - 4px)';
    }};
    width: 360px;
  `,
  title: css`
    font-family: Rubik-Regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #161616;
  `
};
